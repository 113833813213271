import LayoutPage from "components/LayoutPage/LayoutPage";
import React, { Component, ComponentType, FC } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import DashboardBillingAddress from "./DashboardBillingAddress";
import DashboardEditProfile from "./DashboardEditProfile";
import DashboardPosts from "./DashboardPosts";
import DashboardRoot from "./DashboardRoot";
import DashboardSubcription from "./DashboardSubcription";
import DashboardSubmitPost from "./DashboardSubmitPost";
import { Helmet } from "react-helmet";

export interface PageDashboardProps {
  className?: string;
}

interface DashboardLocationState {
  "/"?: {};
  "/info"?: {};
  "/posts"?: {};
  "/edit-profile"?: {};
  "/subscription"?: {};
  "/billing-address"?: {};
  "/submit-post"?: {};
  "/account"?: {};
}

interface DashboardPage {
  sPath: keyof DashboardLocationState;
  exact?: boolean;
  component: ComponentType<Object>;
  emoij: string;
  pageName: string;
}

const subPages: DashboardPage[] = [
  {
    sPath: "/",
    component: DashboardRoot,
    emoij: "🕹",
    pageName: "야나부적금",
  },
];

class PageDashboardWithClass extends Component<PageDashboardProps> {

  componentWillMount() {
    console.log('@@');
    // check metamask
    // const ethereum = window.ethereum;
    // if(ethereum) {
    //   ethereum.on('accountsChanged'), function (accounts) {
    //     console.log(accounts[0]);
    //   }
    // }
  }

  render() {
    let path = '/';
    let className = 'PageDashboard';

    return(
      <div className={`nc-PageDashboard ${className}`} data-nc-id="PageDashboard">
      <Helmet>
        <title>야나부닷컴</title>
      </Helmet>
      <LayoutPage
        heading="야나부"
        subHeading="야 나도 부자될 수 있어. 우리 같이 부자됩시다."
      >
        <div className="flex flex-col space-y-8 xl:space-y-0 xl:flex-row">
          <div className="flex-grow">
            <Switch>
              {subPages.map(({ component, sPath, exact }, index) => {
                return (
                  <Route
                    key={index}
                    exact={exact}
                    component={component}
                    path={!!sPath ? `${path}${sPath}` : path}
                  />
                );
              })}
              <Redirect to={path + "/"} />
            </Switch>
          </div>
        </div>
      </LayoutPage>
    </div>
    );
  }
}


const PageDashboard: FC<PageDashboardProps> = ({ className = "" }) => {
  let { path, url } = useRouteMatch();

  return (
    <div className={`nc-PageDashboard ${className}`} data-nc-id="PageDashboard">
      <Helmet>
        <title>야나부닷컴</title>
      </Helmet>
      <LayoutPage
        subHeading="야 나도 부자될 수 있어. 우리 같이 부자됩시다."
        headingEmoji="⚙"
        heading="야나부"
      >
        <div className="flex flex-col space-y-8 xl:space-y-0 xl:flex-row">
          <div className="flex-grow">
            <Switch>
              {subPages.map(({ component, sPath, exact }, index) => {
                return (
                  <Route
                    key={index}
                    exact={exact}
                    component={component}
                    path={!!sPath ? `${path}${sPath}` : path}
                  />
                );
              })}
              <Redirect to={path + "/"} />
            </Switch>
          </div>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageDashboardWithClass;
