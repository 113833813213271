// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { doc, setDoc, collection, getDocs } from "firebase/firestore";

import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/yanabu-profile.jpeg";
import React, { FC, useState} from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import DashboardPosts from "./DashboardPosts";

import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";

import MasterChef from './YanabuMasterChef';



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCL4cxm58vJH6LR3IZa4XP5SJAuJgNjuX4",
  authDomain: "yanabu-fund.firebaseapp.com",
  projectId: "yanabu-fund",
  storageBucket: "yanabu-fund.appspot.com",
  messagingSenderId: "705970925325",
  appId: "1:705970925325:web:260fe96ebc910e95b38a44",
  measurementId: "G-R64EBC68HM"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore();




class PageRaking extends React.Component<{}, { cnt: number, staked_all:number}> {
  holder: { id: number; address: string; staked: number; rate:number; nickname:string;}[];
  bscAddress: any;
  masterChef: any;
  constructor(props: {}) {
    super(props);
    this.masterChef = new MasterChef();
    this.holder = [
    ]
    this.state={
      cnt:0,
      staked_all:0
    }
  }


  componentDidMount() {
    this.setState({cnt:0});
    this.fetchHolders();
    // this.registerAll();
  }

  updateBSCAddress = async(evt:any) => {
    console.log('updateBSCAddress',evt.target.value);
    this.bscAddress = evt.target.value;
  };

  OnSetAddress = async() => {
    // need to check number or not
    console.log(this.bscAddress);
    
    this.registerAddress(this.bscAddress+"");
  }

  setHolder = (address: string, staked: number) =>{

    var staked_fixed = Number(staked.toFixed(1));
    var staked_all = 1000000;
      // show item to ui
      var holderIdx = this.holder.findIndex( h => h.address = address);
      if(holderIdx == -1) {
        var idx = this.holder.length+1;
        var nickname = "";
        this.holder.push({id:idx, address:address,staked:staked_fixed, rate: Number(staked_fixed/staked_all), nickname })
        // this.holder.push({id:cnt, address:key,staked:value, rate:Number(value/staked_all)});


        this.setState({cnt:idx});
      }
      else {
        this.holder[holderIdx].staked = Number(staked_fixed);
        this.setState({cnt:this.holder.length});
      }
  }

  registerAddress = (address: string) => {
    var address_lower = address.toLowerCase();
    this.masterChef.getStakedAllYNB(address_lower).then((a: any) =>{
      console.log('result22', a);
      this.register(a.address, a.tokenStaked, "");
      this.setHolder(a.address,a.tokenStaked);
    }).catch((e: any)=>{
      console.log('error', e);
    });
  }

  async register(address: string, staked: number, nickname: string) {
    try {
      const holderRef = doc(db, 'holder', address);
      setDoc(holderRef, 
        {
          staked: staked,
          nickname: nickname
        },{ merge: true }
      );
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  fetchHolders = async () => {
    const querySnapshot = await getDocs(collection(db, "holder"));
    type ObjType = {
      [index: string]: number
    }
    var h1_set:ObjType= {
    }


    var staked_all = 0;
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      var address = doc.id;
      var staked = doc.data().staked;
      var staked_fixed = staked.toFixed(1);
      h1_set[address.toLowerCase()] = Number(staked_fixed);
      staked_all += h1_set[address.toLowerCase()];
    });
    var cnt = 0;


    for (const [key, value] of Object.entries(h1_set)) {
      if(value >0) {
        var nickname = "";
        this.holder.push({id:cnt, address:key,staked:value, rate:Number(value/1000000), nickname});
      }
    }


    this.holder.sort((a,b) =>{
      return b.staked - a.staked;
    })
    for (const h of this.holder) {
      h.id = cnt;
      cnt++;      
    }
    console.log('cnt', cnt);
    this.setState({cnt, staked_all});
  }


  render() {
    const cnt = this.state.cnt;
    const staked_all = this.state.staked_all;

    return (
      <div className="p-5 mx-auto bg-white rounded-[40px] shadow-lg sm:p-10 mt-10 lg:mt-20 lg:p-16 dark:bg-neutral-900 ml-20 mr-20">
        <span className="block text-lg mb-1">
          👋 야나부 홀더 랭킹 
        </span>
        <span className="block text-s mb-1">
          홀더 수: {cnt} (직접 추가하셔야합니다. 1시간마다 갱신됨.) 스테이킹 한 사람만 보입니다.
        </span>
        <span className="block text-s mb-3">
          총 스테이킹: {staked_all.toFixed(1)} YNB
        </span>

        <div className="mt-4 space-x-3">
          <Input
            className="mb-4"
            placeholder="bsc address"
            type="text"
            onChange={this.updateBSCAddress.bind(this)}
          />
          <ButtonPrimary type="button"
            onClick={this.OnSetAddress.bind(this)}
          >
            추가/갱신
          </ButtonPrimary>
        </div>

        <DashboardPosts
          holder = {this.holder} 
          cnt = {cnt}
        ></DashboardPosts>


      </div>


    );
  }
}

export default PageRaking;






// registerAll() {
//   var holder = ["0xD2171075c328E573be28488DA1e3C0BE6b2ef524",
//   "0x5295387771e5038eaf6C71eC96043EF99557b669",
//   "0x785215cB3e527830B1a10584728deEB586965008",
//   "0xe1e2B726A687f021000376836ed4B2eF7Dd6A783",
//   "0x0d215207AD6A1218c3073EB750eBC37dad2c9e50",
//   "0x1A6ebA3897Fb4799FB6372F32771Ee821370AB7E",
//   "0xb00bd3a54b667Ae6E3510992215C3307c15ef0e0",
//   "0xa806765b2565a36560319A07a630DFEf8286FD6E",
//   "0xc590175E458b83680867AFD273527Ff58f74c02b",
//   "0x090c3E82D4ef72BF2eDB895Ce6306E949E2aB62B",
//   "0xc470c3cf941Ad4A4444453B8517367F95BaF5F30",
//   "0x91d07F1a287CA94AE0C54b66AD3A53C4042dD264",
//   "0x3A69812207d66e3C6615da0617Ac0F87a3D9D3D8",
//   "0xF2D6cDE4E9b4EA154C9312879f726cf1450bD10C",
//   "0x1DA2CFDccd1AC5F1093a8c146260C5DC7159ed47",
//   "0x90a60FBf77F45D7BBEaDeaA739d71Bd95aE936fc",
//   "0xf332984a44AEF4698D89AF4504A518D59eC788EA",
//   "0x16ac58f871C8b3cA54c6BC43C107710db8c1675B",
//   "0x858113CD04BD80c9E725DF1a8C6cEFFCFD04d8c0",
//   "0x6c82623EC54220b5DBeAc5Cb7b7b7C46DB4F8340",
//   "0xf68c1CCc379482223e263b08718e75f8ab74aee3",
//   "0x8faDe62c6Cd202975259bbA9Be1053021825E0d3",
//   "0xc0549C26F1f625a0Fb8cCe0aDaBdda4BfeAcf8d5",
//   "0x4E2aC8C1844cF40922518C80FE2b41B4a97cA966",
//   "0xF6096983D0faa6d399fe214520A1DD54569CaB6d",
//   "0xD15F8B0f0651346008667aCe763EB4398479d2bB",
//   "0xFE7DFd1C46CfD7985b87c2d738d99942dfBbE0EB",
//   "0x2E21E298404668d162A70C7Da4011dCee0bD73f4",
//   "0x5Ce908A6ffB393e9cB88dD8D6e8594A6a14a0B60",
//   "0x60D50164733c6895775F5bF4f8c3A7004C9F3c66",
//   "0xE38f9ED5D5998BCD561c69282d9e729CBAD3D20d",
//   "0xdB27dA66193f6dCA89608bb90C09773Bb548B669",
//   "0xe50090f6f7bccD51c1753989444C6C4757b0845f",
//   "0xA3f933E343F42aF6d24be409d5b45A619A9047f5",
//   "0xafBc544A44dD5287b27566f0729CCcdcBA2E7fC5",
//   "0x5D1E5c74BAA33179AC2Bd9039adceDC2aAe910f0",
//   "0x435115b69f6c7377453e7122CcE8ce645Af30dc5",
//   "0x10ED43C718714eb63d5aA57B78B54704E256024E",
//   "0xe7774A93B52351D9F9910aB4D1a1b31E94bCB476",
//   "0xca864A956e28FDb545f89b0F623E02293a4EFe60",
//   "0xb166A3cEf29112670eF83064C66E086814abD5DC",
//   "0xab57e358B394eFb3165D83A180113920608FfFd4",
//   "0x0000000000dba7f30ba877d1d66e5324858b1278",
//   "0x056f6dd8f05579b55bd1146d546299a40a53dedb",
//   "0x075bddbb09e1b16ed450e80590db9f1254473641",
//   "0x090c3e82d4ef72bf2edb895ce6306e949e2ab62b",
//   "0x0d215207ad6a1218c3073eb750ebc37dad2c9e50",
//   "0x11ededebf63bef0ea2d2d071bdf88f71543ec6fb",
//   "0x1700a2254d5ba1e010628ab1047a0c07679dafc4",
//   "0x1929ac6ddbe52d027d0bec210be6864ed16a738c",
//   "0x1a6eba3897fb4799fb6372f32771ee821370ab7e",
//   "0x21e6dba418a3053a23ee0602f9572f85e4be4f5c",
//   "0x2a33e8718118a51d6cda7bb7ffe24a8259eae943",
//   "0x2e21e298404668d162a70c7da4011dcee0bd73f4",
//   "0x2e5cf537182bd6c88475bf62fb9dd37ba0ae1966",
//   "0x34c8b3ce130b4ddeebcc0b776482247f7400acb7",
//   "0x34e044fa165bf21a972797de5cf680ab02b41238",
//   "0x35648c999209847f2561ffab999fc0a64f6b2cd3",
//   "0x3c33b0471d8702b529c1afca83d232f6a5043b2b",
//   "0x4433493b07ddb968f549672add78b36b2fbb5f2c",
//   "0x4fe3e8c4429ac6d24a362d12585156d8e9f67494",
//   "0x5004fd73eb595d83ddd51d6da014927c4014fd87",
//   "0x5075633e78c6d424426a21c1bab5b2956feb68cf",
//   "0x51390e66a3f9e953b4c4308bc4256c3a91d324e6",
//   "0x5295387771e5038eaf6c71ec96043ef99557b669",
//   "0x54f8e53cbc4f7ba271d2f34bddef27e143feec0a",
//   "0x57e8cadb6bd7af7b456c0c7f6b90a8c7c13ddad1",
//   "0x5ce908a6ffb393e9cb88dd8d6e8594a6a14a0b60",
//   "0x5dfc2d57999e8c86b026b5dfe5bff59742114cf3",
//   "0x5f3c4697c825ff698535dcb0e8a67fd0587ce8c8",
//   "0x60d50164733c6895775f5bf4f8c3a7004c9f3c66",
//   "0x6c82623ec54220b5dbeac5cb7b7b7c46db4f8340",
//   "0x7107cb24882b598c9a5b5c011d43a80a27ac5e75",
//   "0x71365c79250f5efae563589b6141f804d7f4d97e",
//   "0x72523c15cb8edc5a7dda2428a0c7d8bdb2ab1cd0",
//   "0x785215cb3e527830b1a10584728deeb586965008",
//   "0x858113cd04bd80c9e725df1a8c6ceffcfd04d8c0",
//   "0x8fade62c6cd202975259bba9be1053021825e0d3",
//   "0x908d5cf9fbb2ae1fb9b80ec046d5b1580cc98b97",
//   "0x91d07f1a287ca94ae0c54b66ad3a53c4042dd264",
//   "0x9a74b1d78a43e2fad0a2cc4dd7de81b8d37161a4",
//   "0x9f1c6b37dede7584e722681c8f9d55e5cf1788f5",
//   "0xa05e01e9df824ccad284895fff43b043e2133f50",
//   "0xa3f933e343f42af6d24be409d5b45a619a9047f5",
//   "0xafbc544a44dd5287b27566f0729cccdcba2e7fc5",
//   "0xb166a3cef29112670ef83064c66e086814abd5dc",
//   "0xb359fceb69655739875503fe99a7015b2ad7eea6",
//   "0xb490b60a4120c25085ab3ae2b92c7124606f69bd",
//   "0xbbaaf3d0323084ddcdf92fd9ce76d6ccc99deb15",
//   "0xbfbd0c5f45596d563a9401025957da540f4b3a10",
//   "0xc4dd8f3033a00e697b24730a50d7eeea7dddcf89",
//   "0xc50be487d1657b9385384dc8dcc1758223d3cc9a",
//   "0xca864a956e28fdb545f89b0f623e02293a4efe60",
//   "0xcd88d7573818abe26b02f4bf19c4db44aff3c591",
//   "0xd2171075c328e573be28488da1e3c0be6b2ef524",
//   "0xd3ca08900b4d855440a586847b0d06093ba24f4b",
//   "0xdf69d72196036b101f0cd332bea58ecf9682db02",
//   "0xdfee9d0f5d58b7445c3928154db0e78559e782cd",
//   "0xe0628fe2309974943758a058ef0a520bc8f52e5f",
//   "0xe1e2b726a687f021000376836ed4b2ef7dd6a783",
//   "0xe4892c7156c9a8225679c0aa78bb8d791d6dc213",
//   "0xe63b9bef357b84d7fd2ad0f7f14925cdbae9a42f",
//   "0xe6ce9a3dbba9fcfc89325ca67a90c79816533d23",
//   "0xf2d6cde4e9b4ea154c9312879f726cf1450bd10c",
//   "0xf332984a44aef4698d89af4504a518d59ec788ea",
//   "0xf3fff88a7d8de4c07ad8c97639107113281e4945",
//   "0xf68c1ccc379482223e263b08718e75f8ab74aee3",
//   "0xf809a4156b5abd7b74a94d8a99c93ccbdadb7d07",]
//   for (const h of holder) {
//     var address_lower= h.toLowerCase();
//     this.masterChef.getStakedAllYNB(address_lower).then((a: any) =>{
//       console.log('result', a);
//       this.register(a.address, a.tokenStaked, "");
//     });
//   }
// }