
import {MASTERCHEF_ABI} from "../../utils/contracts/yanabu_master_chef";
import {BUSD_ABI} from "../../utils/contracts/busd_token.js";
import {WBNB_ABI} from "../../utils/contracts/wbnb_token.js";
import {YANABU_ABI} from "../../utils/contracts/yanabu_token.js";
import {PAIR_ABI} from "../../utils/contracts/pair_token.js";

import Web3 from 'web3';


export default class MasterChef {

    constructor() {
        // master chef
        this.contract_address = '0xb563bad29ac970b588c32f76915ebaf9ed62f828';
        this.web3 = new Web3('https://bsc-dataseed1.binance.org:443');
        this.myContract = new this.web3.eth.Contract(MASTERCHEF_ABI, this.contract_address);
        this.busdContract = new this.web3.eth.Contract(BUSD_ABI, '0xe9e7cea3dedca5984780bafc599bd69add087d56');
        this.wbnbContract = new this.web3.eth.Contract(WBNB_ABI, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c');
        this.ynbContract = new this.web3.eth.Contract(YANABU_ABI, '0x4d34b7D0a31b033cc34809CD6F656DbBEE2dD1FC');
        this.bnbynbContract = new this.web3.eth.Contract(PAIR_ABI, '0x1A20870216e4972B66a375E8AcCa6fd126341F1e');
        this.busdynbContract = new this.web3.eth.Contract(PAIR_ABI, '0xf27d90170f21ab40bb9043151dfa26f3f16d828e');

        this.lp_pair = {
            'bnb_ynb_lp':{
                id:1,
                lpToken:'0x1A20870216e4972B66a375E8AcCa6fd126341F1e'
            },
            'busd_ynb_lp':{
                id:0,
                lpToken:'0xf27d90170f21ab40bb9043151dfa26f3f16d828e'
            },
            'ynb_pool':{
                id:3,
                lpToken:'0x4d34b7D0a31b033cc34809CD6F656DbBEE2dD1FC'
            }
        }



    }

    async getStakedAllYNB(address){
        var result = await Promise.all([
            this.getStakedYNB(address,'bnb_ynb_lp'), 
            this.getStakedYNB(address,'busd_ynb_lp'),
            this.getStakedYNB(address,'ynb_pool')]);
        console.log(address, result);
        var tokenStaked = 0;
        for (const token of result) {
            tokenStaked +=token;
        }
        return {address, tokenStaked};
    }

    async getStakedYNB(address, lp){
        // 1. get user staked LP
        var staked = await this.userInfo(address,lp);

        // 2. get total LP
        var total = 0;
        if(lp == 'busd_ynb_lp')
            total = await this.getTotalBUSDYNBLP();
        else if(lp == 'bnb_ynb_lp')
            total = await this.getTotalBNBYNBLP();
        else if(lp == 'ynb_pool') {
            total = 0;
        }

        if(total == 0){
            return staked;
        }
        else {
             // 3. YNB in LP *(staked LP / total LP)
             var ynbInPool = await this.getYNBFromLP(lp);
            var ynb = ynbInPool*(staked/ total);
            return ynb;
        }

    }

    async pools(){
        var length = await this.poolLength();

        for (let idx = 0; idx < length; idx++) {
            // const element = array[idx];
            await this.poolInfo(idx);
        }
    }


    async getTotalYNB() {
        var balance = await this.ynbContract.methods.totalSupply().call() 
        balance /= 1000000000000000000.0;
        return balance;
    }

    async getTotalBUSDYNBLP() {
        var balance = await this.busdynbContract.methods.totalSupply().call() 
        balance /= 1000000000000000000.0;
        return balance;
    }

    async getTotalBNBYNBLP() {
        var balance = await this.bnbynbContract.methods.totalSupply().call() 
        balance /= 1000000000000000000.0;
        return balance;
    }

    async getYNBFromLP(lp) {
        var balance = await this.ynbContract.methods.balanceOf(this.lp_pair[lp].lpToken).call() 
        balance /= 1000000000000000000.0;
        return balance;
    }

    async getBNBFromLP(){
        var balance = await this.wbnbContract.methods.balanceOf(this.lp_pair['bnb_ynb_lp'].lpToken).call() 
        balance /= 1000000000000000000.0;
        return balance;
    }

    async getBUSDFromLP(){
        var balance = await this.busdContract.methods.balanceOf(this.lp_pair['busd_ynb_lp'].lpToken).call() 
        balance /= 1000000000000000000.0;
        return balance;
    }



    async userInfo(address, lp){
        var result = await this.myContract.methods.userInfo(this.lp_pair[lp].id, address).call()
        // console.log(result);
        // console.log('LP amount' , result.amount / 1000000000000000000.0);
        return result.amount / 1000000000000000000.0;
    }


    poolLength(){
        return this.myContract.methods.poolLength().call()
    }

    poolInfo(idx){
        try {
            this.myContract.methods.poolInfo(idx).call()
            .then( (result)=>{
                console.log(idx);
                console.log(result);
            });
        } catch (error) {
            console.log(error);
        }
    }
    
    getLatestBlock() {
        return this.web3.eth.getBlockNumber();
    }

    async getLatestEvent() {
        var latestBlockNumTo = await this.getLatestBlock();
        var latestBlockNumFrom = latestBlockNumTo - 4000;

        while(true) {
            var event = await this.getLatestEventOnce(latestBlockNumTo, latestBlockNumFrom);
            console.log('length', latestBlockNumTo, latestBlockNumFrom, event.length);
            latestBlockNumTo = latestBlockNumFrom;
            latestBlockNumFrom = latestBlockNumFrom - 4000;
        }

    }

    async getLatestEventOnce(latestBlockNum1, latestBlockNum2) {
        var length = await this.myContract.getPastEvents('Deposit', {
            fromBlock: latestBlockNum2,
            toBlock: latestBlockNum1
        }, function(error, events){ 
            //console.log('@@@ 1',events.length); 
        })
        // .then(function(events){
        //     console.log('@@@ 2',events.length) // same results as the optional callback above
        // });
        return length;
    }    
};
