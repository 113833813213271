import React, {useState} from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { useWeb3React } from "@web3-react/core";
import { ethers } from 'ethers'
import {AIRDROP_ABI} from "utils/constant";
import detectEthereumProvider from '@metamask/detect-provider';


const DashboardRoot = () => {
  const {active, account} =  useWeb3React();
  const [tokenBalance, setTokenBalance] = useState(0);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [claimToken, setClaimAvailableToken] = useState(0);
  let one_token = "1000000000000000";
  let providerInfo : any;
  const onReady = async() => {
    console.log("OnReady", account, active);

    providerInfo = await detectEthereumProvider();
    await providerInfo.request({ method: 'eth_requestAccounts' });
    let provider = new ethers.providers.Web3Provider(providerInfo);
    const airdropContract = new ethers.Contract("0x72523c15cb8EDc5A7DdA2428A0c7d8bdb2AB1cD0", 
    AIRDROP_ABI, provider);
    
    airdropContract.getBalanceForAirdrop()
    .then((result: ethers.BigNumber) => {
      let one = ethers.BigNumber.from(one_token);
      var smallNumber = result.div(one);
      console.log('getBalanceForAirdrop', smallNumber.toNumber());
      setTokenBalance(smallNumber.toNumber() / 1000);
      }).catch((a: any)=>{
        console.log(a);
      })


    if(active) {

      airdropContract.getAirdropBalance(account)
      .then((result: ethers.BigNumber) => {
        let one = ethers.BigNumber.from(one_token);
        var smallNumber = result.div(one);
        console.log('getAirdropBalance', smallNumber.toNumber());
        setUserTokenBalance(smallNumber.toNumber() / 1000);
        })

      airdropContract.airdropAmountOf(account)
      .then((result: ethers.BigNumber) => {
        let one = ethers.BigNumber.from(one_token);
        var smallNumber = result.div(one);
        console.log('airdropAmountOf', smallNumber.toNumber());
        setClaimAvailableToken(smallNumber.toNumber() / 1000);
        })
    }
  }
  onReady();

  const onClaim = async () => {
    if(active == false) {
      // dialog
      alert('지갑을 연결해주세요.');
    }
    else {
      let provider = new ethers.providers.Web3Provider(providerInfo);
      const airdropContract = new ethers.Contract("0x72523c15cb8EDc5A7DdA2428A0c7d8bdb2AB1cD0", 
      AIRDROP_ABI, provider.getSigner());

      airdropContract.claim()
      .then((result: any) => {
        console.log('claim', result);
      }).catch( (error:any) =>{
        console.log('error', error);
        var msg = "";
        if(error.data) {
          msg = error.data.message;
          alert(msg);
        }
      });
    }
  };
  return (
    <div className="rounded-xl min-h-full text-sm border border-neutral-100 dark:border-neutral-800 p-6 md:text-base">
      <span className="block text-lg mb-3">
        👋 야나부 토큰
      </span>

      <span>
        설명 : 야나부(YNB) 토큰은 야나부팜의 거버넌스 코인이자 야나부 팬 토큰입니다.
      <br/>
      <br/>
      야나부 토큰 홀더는 야나부팜에서 발생하는 수익과 야나부 유튜브 수익 일부의 주인입니다. 
      <br/>
      <br/>
      야나부 채널이 성장할수록, 야나부 토큰 가치도 오를 것으로 기대합니다.
      </span>
      <br/>
      <br/>
      <span>
       야나부 팜: <a style={{color: "blue"}} target="_blank" href="https://yanabu.com">https://yanabu.com</a>
      </span>
      <br/>
      <span>
       야나부 유튜브: <a style={{color: "blue"}} target="_blank" href="https://www.youtube.com/yanabu">https://www.youtube.com/yanabu</a>
      </span>
      <br/>
      <span>
       야나부 카카오톡: <a style={{color: "blue"}} target="_blank" href="https://open.kakao.com/o/gn5ERzUd">https://open.kakao.com/o/gn5ERzUd</a>
      </span>

      <br/>
      <br/>

      <span className="block text-lg mb-3">
        야나부 토큰 차별점
      </span>
      1. 야나부 토큰 홀더는 야나부 vault 수익을 나눠갖습니다.
      <br/>
      2. 야나부 밋업에 참여할 수 있습니다.
      <br/>
      3. 야나부 거버넌스 투표에 참여할 수 있습니다. 
      <br/>
      (거버넌스 투표를 통하여 야나부팜의 운영방향을 결정합니다.)

      <br/>
      <br/>
{/* 
      <span className="block text-lg mb-3">
        에어드랍
      </span>
      1. 이벤트를 통해 에어드랍 자격을 얻습니다.
      <br/>
      2. 에어드랍 자격을 얻은 사람은 BSC 지갑을 연결 한 후, YNB를 수령할 수 있습니다.
      <br/>
      <br/>

      <div className="rounded-xl min-h-full text-sm border border-neutral-100 dark:border-neutral-800 p-6 md:text-base">

        <div className="mt-4 space-x-3">
          <span>
           남은 에어드랍 토큰 개수: {tokenBalance} YNB
          </span>

        </div>

        <span>
          사용자의 토큰 개수: {userTokenBalance} YNB
        </span>        

        <div className="mt-4 space-x-3">
          <span>
           사용자가 받을 토큰 개수: {claimToken} YNB
          </span>
          <ButtonPrimary type="button"
           onClick={onClaim}
          >
            Claim
          </ButtonPrimary>
        </div>

      </div> */}

    </div>



  );
};

export default DashboardRoot;
