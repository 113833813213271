import React, { useState, FC } from "react";
import { Helmet } from "react-helmet";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";

import { useWeb3React } from "@web3-react/core";
import { ethers } from 'ethers'
import {AIRDROP_ABI} from "utils/constant";
import detectEthereumProvider from '@metamask/detect-provider';


export interface PageAdminProps {
  className?: string;
}

const PageAdmin: FC<PageAdminProps> = ({ className = "" }) => {
  const {active, account} =  useWeb3React();
  let tokenBalance:number = 0;
  let bscAddress:string = "";
  const [airDropTokenBalance, setAirdropTokenBalance] = useState(0);
  const [bscAddressArray, setBscAddressArray] = useState(["empty"]);

  
  const updateBSCAddress = async(evt:any) => {
    console.log('updateBSCAddress',evt.target.value);
    bscAddress = evt.target.value;
  };

  const updateTokenBalance = async(evt:any) => {
    console.log('updateTokenBalance',evt.target.value);
    tokenBalance = evt.target.value*1;
  };

  const OnSetBalance = async() => {
    // need to check number or not
    setAirdropTokenBalance(tokenBalance);
  }

  const OnSetAddress = async() => {
    // need to check number or not

    var tempArray = [...bscAddressArray];
    tempArray = tempArray.filter(a => a != "empty");
    tempArray.push(bscAddress);
    console.log('@@', bscAddress);
    console.log('@@', tempArray);
    setBscAddressArray(tempArray);
  }

  const OnSetAirdrop = async() => {
    console.log('airDropTokenBalance', airDropTokenBalance);
    let one = ethers.BigNumber.from("1000000000000000");
    let tokenBalanceBigNumber = ethers.BigNumber.from(1000*airDropTokenBalance+"");
    

    console.log('bscAddressArray', bscAddressArray);
    let providerInfo : any;
    providerInfo = await detectEthereumProvider();
    await providerInfo.request({ method: 'eth_requestAccounts' });

    let provider = new ethers.providers.Web3Provider(providerInfo);
    const airdropContract = new ethers.Contract("0x72523c15cb8EDc5A7DdA2428A0c7d8bdb2AB1cD0", 
    AIRDROP_ABI, provider.getSigner());

    airdropContract.setClaimAmountArray2(bscAddressArray, one.mul(tokenBalanceBigNumber))
    .then((result: any) => {
      console.log('setClaimAmountArray2', result);
    }).catch( (error:any) =>{
      console.log('error', error);
      var msg = "";
      if(error.data) {
        msg = error.data.message;
        alert(msg);
      }
    });
  }

  

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAdmin"
    >
      <Helmet>
        <title>야나부닷컴</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">


     


      <div className="mt-4 space-x-3">

        <div>
          {bscAddressArray.map((item, index) => (
            <div>
              <span>@@ {item}</span>
              <br/>
            </div>
          ))}
        </div>

        <span className="text-lg mb-3">
          에어드랍할 토큰 수량: {airDropTokenBalance}
        </span>

        <ButtonPrimary type="button"
              onClick={OnSetAirdrop}
            >
          에어드랍 리스트 전송하기
        </ButtonPrimary>
      </div>


        <div className="mt-4 space-x-3">
          <Input
            placeholder="bsc address"
            type="text"
            onChange={updateBSCAddress}
          />
          <ButtonPrimary type="button"
            onClick={OnSetAddress}
          >
            추가하기
          </ButtonPrimary>
        </div>

        <div className="mt-4 space-x-3">
          <Input
            placeholder="토큰 수량"
            type="number"
            onChange={updateTokenBalance}
          />
          <ButtonPrimary type="button"
            onClick={OnSetBalance}
          >
            에어드랍 수량 설정하기
          </ButtonPrimary>
        </div>

        {/* <BgGlassmorphism /> */}

      </div>
    </div>
  );
};

export default PageAdmin;
