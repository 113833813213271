import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/yanabu-profile.jpeg";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>야나부닷컴</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 야나부입니다."
          btnText=""
          subHeading="월급만 모아서는 부자되기 힘듭니다. 야나부와 함께 코인, DeFi 투자해봐요."
          subHeading1="월급만 모아서는 부자되기 힘듭니다."
          subHeading2="함께 코인, DeFi 투자해봐요."
        />

        {/* <SectionFounder /> */}

        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionStatistic />
        </div> */}

        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
