import React, { useEffect } from "react";
import { DEMO_POSTS, DEMO_POSTS_VIDEO } from "data/posts";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionMagazine6 from "./SectionMagazine6";

import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";

// DEMO POST FOR MAGAZINE SECTION
const MAGAZINE1_TABS = ["공지사항", "투표", "자유게시판"];
const MAGAZINE1_POSTS = DEMO_POSTS.filter((_, i) => i >= 8 && i < 16);




class PageHome extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
  }


  componentDidMount() {
  }

  render() {
    // useEffect(() => {
    //   const $body = document.querySelector("body");
    //   if ($body) {
    //     $body.className = "theme-animals theme-lightBlue-coolGrey";
    //   }
    //   return () => {
    //     if ($body) {
    //       $body.className = "";
    //     }
    //   };
    // }, []);
  
    return (
      <div className="nc-PageHomeDemo4 relative">
        {/* ======== ALL SECTIONS ======== */}
        <div className="relative">
          
          <div className="relative overflow-hidden">
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism />

            {/* ======= START CONTAINER ============= */}
            <div className="container relative">
              <div className="mt-4 space-x-3">
              <Input
                className="mb-4 w-80"
                placeholder="닉네임"
                type="text"
                // onChange={this.updateBSCAddress.bind(this)}
              />
              <ButtonPrimary type="button"
                // onClick={this.OnSetAddress.bind(this)}
              >
                설정
              </ButtonPrimary>
              </div>

              <SectionMagazine6
                className="py-8 lg:py-12"
                posts={MAGAZINE1_POSTS}
                tabs={MAGAZINE1_TABS}
              />
  
            </div>
  
         
          </div>
        </div>
      </div>
    );
  }

};

// const PageHomeDemo4: React.FC = () => {

// };

export default PageHome;
