import React from "react";
import MyRouter from "routers/index";

import {Web3ReactProvider} from "@web3-react/core"
import Web3 from "web3";

function getLibrary(provider: any) {
  return new Web3(provider);
}

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Web3ReactProvider getLibrary={getLibrary}>
        <MyRouter />
      </Web3ReactProvider>
    </div>
  );
}

export default App;
