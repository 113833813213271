import {
  MegamenuItem,
  NavItemType,
} from "components/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";




export const NAVIGATION_DEMO_NO_ADMIN: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    // type: "dropdown",
    // children: demoChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
    // type: "dropdown",
    // children: demoChildMenus,
  },  
];


export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    // type: "dropdown",
    // children: demoChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
    // type: "dropdown",
    // children: demoChildMenus,
  },  
  {
    id: ncNanoId(),
    href: "/ranking",
    name: "Ranking",
    // type: "dropdown",
    // children: demoChildMenus,
  },    
  // {
  //   id: ncNanoId(),
  //   href: "/info",
  //   name: "Info",
  //   // type: "dropdown",
  //   // children: demoChildMenus,
  // },    
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Five cols",
  //   type: "megaMenu",
  //   megaMenu: megaMenuDemo,
  // },

  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Fewer cols",
  //   type: "megaMenu",
  //   megaMenu: megaMenu3ItemDemo,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Templates",
  //   type: "dropdown",
  //   children: templateChilds,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Other pages",
  //   type: "dropdown",
  //   children: otherPageChildMenus,
  // },
];
